<template>
  <section class="section contact-us-container">
    <div class="main-txt-container">
      <h1 class="title">{{ $t('contactUsTitle') }}</h1>
      <p class="description">{{ $t('contactUsParagraph1') }}</p>
      <p class="description">{{ $t('contactUsParagraph2') }}</p>
    </div>
    <div class="form-container">
      <div class="forms-inner">
        <form v-show="!showSuccess" @submit.prevent="handleSubmit">
          <div class="field-wrapper">
            <label for="comment">{{ $t('contactUsFormCommentsLabel') }}</label>
            <textarea name="comment" id="comment" maxlength="5000" :placeholder="$t('contactUsFormCommentsPlaceholder')" class="form-textarea form-field-text" v-model="comment" required=""></textarea>
          </div>
          <div class="submit-wrapper">
            <btn custom-class="button-link" :text="$t('submit')"> </btn>
          </div>
        </form>

        <div class="form-success" v-if="showSuccess">
          <img :src="SUCCESS_CHECK" width="205" height="180" alt="verificación de éxito">
          <p class="title">{{ $t('contactUsSuccessHeading') }}</p>
        </div>
        <div class="form-fail" v-else-if="showFailed">
          {{ $t('contactUsErrorMessage') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Btn from '@/components/basic/Btn.vue';
import { ZAPIER_HOOK_CONTACTUS } from '@/constants/apis';
import htFetch from '@/services/htFetch';
import { SUCCESS_CHECK } from '@/constants/cloudinary';

export default {
  name: 'ContactUs',
  components: {
    Btn,
  },
  data() {
    return {
      comment: '',
      showFailed: false,
      showSuccess: false,
    };
  },
  methods: {
    handleSubmit() {
      const data = {
        comment: this.comment,
      };

      htFetch(ZAPIER_HOOK_CONTACTUS, data).then(res => {
        if (res.success) this.showSuccess = true;
        else this.showFailed = true;
        if (res.success) {
          this.showSuccess = true;
          document.getElementById('content').scrollIntoView();
        } else this.showFailed = true;
      });
    },
    openWidget() {
      if (this.$route.query.mobile_app === 'true') this.$zendesk.open();
    },
  },
  created() {
    this.SUCCESS_CHECK = SUCCESS_CHECK;
    this.openWidget();
  },
};
</script>

<style scoped="" lang="scss">
@import '../styles/global/marketing.scss';

.section {
  @extend %section;
}

.contact-us-container {
  @include bp-mediumlarge {
    display: flex;
    justify-content: space-between;
  }
}
.main-txt-container,
.form-container {
  flex: 0 1 48%;
}

.form-container {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background: url('https://res.cloudinary.com/healthytogether/image/upload/v1618867486/htio/contact-us-hero-colors.svg')
      top / 100% 100% no-repeat;
    top: -3.688rem;
    right: 0;
    bottom: 0;
    width: 50.5rem;
    max-width: 100%;
    filter: blur(9.375rem);
  }
}

.title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 3.625rem;
  line-height: 1.2em;
  font-weight: 700;
}

.description {
  font-size: 1.375rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>
